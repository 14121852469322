<template>
  <div class="container">
    <!-- 第一个卡片 -->
    <el-card v-if="current == 1">
      <!-- 风险统计区域 -->
      <div class="statistical">
        <div class="left">
          <div class="riskBg">
            <div class="riskTitle">| 在建项目工地</div>
            <div class="riskNum">{{ riskNum.gongdi }}</div>

          </div>
          <div class="riskBg">
            <div class="riskTitle">| 作业活动风险总计</div>
            <div class="riskNum">{{ riskNum.risk_hd }}</div>
          </div>
          <div class="riskBg">
            <div class="riskTitle">| 设备设施风险总计</div>
            <div class="riskNum">{{ riskNum.risk_sb }}</div>
          </div>


          <div class="riskBg">
            <div class="riskTitle">| 重大风险点总计</div>
            <div class="riskNum">{{ riskNum.risk_jd }}</div>
          </div>
        </div>
        <div class="right"></div>
      </div>
      <!-- 搜索区域 -->
      <div class="search">
        <el-row>
          <el-form ref="searchForm" :model="searchForm" label-width="80px">
            <el-col :span="4">
              <el-form-item label="项目名称">
                <el-input v-model="project_Name"></el-input>
              </el-form-item>
            </el-col>

            <!-- <el-col :span="4">
              <el-form-item label="区域位置">
                <el-input v-model="searchForm.screen"></el-input>
              </el-form-item>
            </el-col> -->
            <el-col :span="4">
              <el-button type="primary" @click="searchProject" class="searhAllBtn">查询</el-button>
              <el-button type="primary" @click="resetProject_Name">重置</el-button>
            </el-col>
          </el-form>
        </el-row>
      </div>
      <!-- 表格区域 -->
      <div class="main">
        <el-table :data="tableData1" style="width: 100%" :header-cell-style="headerCellStyle" :cell-style="cellStyle">
          <el-table-column prop="name" label="项目名称" width="250" align="center">
          </el-table-column>
          <el-table-column prop="corporations" label="所属企业" width="250" align="center">
          </el-table-column>

          <el-table-column prop="risk_hd" label="作业活动重大风险统计" align="center">
          </el-table-column>
          <el-table-column prop="risk_sb" label="设备设施重大风险统计" align="center">
          </el-table-column>

          <el-table-column prop="risk_zd" label="重大风险统计" align="center">
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <div @click="getPorjectRisk(scope)">查看详情</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="footer">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
          :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>

      <!-- <div class="out"></div> -->


    </el-card>
    <!-- 第二个卡片 -->
    <el-card v-else-if="current == 2">
      <!-- 风险统计区域 -->
      <div class="statistical">
        <div class="left">

          <div class="riskBg">
            <div class="riskTitle">| 作业活动风险总计</div>
            <div class="riskNum">{{ projectRiskNum.risk_hd }}</div>
          </div>
          <div class="riskBg">
            <div class="riskTitle">| 设备设施风险总计</div>
            <div class="riskNum">{{ projectRiskNum.risk_sb }}</div>
          </div>

          <div class="riskBg">
            <div class="riskTitle">| 风险点总计</div>
            <div class="riskNum">{{ projectRiskNum.risk_all }}</div>
          </div>
          <div class="riskBg">
            <div class="riskTitle">| 重大风险点总计</div>
            <div class="riskNum">{{ projectRiskNum.risk_jd }}</div>
          </div>
        </div>
        <div class="right" v-if="flag">
          <el-button type="primary" @click="goProject">返回上级</el-button>
        </div>
      </div>
      <!-- 项目信息展示 -->
      <div class="projectInfo">
        <div>
          项目名称:
          <span>{{ projectName }}</span>
        </div>
        <div>
          所属公司:
          <span>{{ companyName }} </span>
        </div>
        <div></div>
      </div>

      <!-- 搜索区域 -->
      <div class="search">
        <el-row>
          <el-form ref="searchForm" :model="searchForm" label-width="80px">

            <el-col :span="4">
              <el-form-item label="风险名称">
                <el-input v-model="searchForm.name"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="4">
              <el-button style="padding-left: 10px" type="primary" @click="searhProjectBtn">查询</el-button>
              <el-button type="primary" @click="resetHandle">重置</el-button>
            </el-col>
          </el-form>
        </el-row>
      </div>
      <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="作业活动风险" name="1">
          <el-table :data="workTableData" style="width: 100%" :cell-style="cellStyle"
            :header-cell-style="headerCellStyle">
            <el-table-column prop="name" label="分部分项工程名称" align="center">
            </el-table-column>
            <el-table-column prop="riskname" label="风险点名称" align="center">
            </el-table-column>
            <el-table-column prop="risktype" label="工作步骤/工作内容" align="center">
            </el-table-column>
            <el-table-column prop="hazard" label="主要危害因素（人，物，环，管）" align="center">
            </el-table-column>
            <el-table-column prop="riskpoint" label="潜在事故类型" align="center">
            </el-table-column>
            <el-table-column prop="riskrank" label="风险等级" align="center">


            </el-table-column>
            <el-table-column prop="" label="现有控制措施" show-overflow-tooltip> <template slot-scope="scope">
                <div>
                  <ul>
                    <li>
                      管控措施： {{ scope.row.detailss.controls }}
                    </li>
                    <li>
                      应急措施： {{ scope.row.detailss.manager }}</li>
                    <li>
                      个人措施：{{ scope.row.detailss.personage }}</li>
                    <li>
                      工程措施： {{ scope.row.detailss.projectrola }}</li>
                  </ul>
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="" label="纠正措施" align="center">
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <div @click="goThree(scope.row)">查看详情</div>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="设备设施风险" name="2">
          <el-table :data="equimentTableData" style="width: 100%" :cell-style="cellStyle"
            :header-cell-style="headerCellStyle">
            <el-table-column prop="name" label="分部分项工程名称" align="center">
            </el-table-column>
            <el-table-column prop="riskname" label="风险点名称" align="center">
            </el-table-column>
            <el-table-column prop="risktype" label="工作步骤/工作内容" align="center">
            </el-table-column>
            <el-table-column prop="hazard" label="主要危害因素（人，物，环，管）" align="center">
            </el-table-column>
            <el-table-column prop="riskpoint" label="潜在事故类型" align="center">
            </el-table-column>
            <el-table-column prop="riskrank" label="风险等级" align="center">


            </el-table-column>
            <el-table-column prop="" label="现有控制措施" show-overflow-tooltip> <template slot-scope="scope">
                <div>
                  <ul>
                    <li>
                      管控措施： {{ scope.row.detailss.controls }}
                    </li>
                    <li>
                      应急措施： {{ scope.row.detailss.manager }}</li>
                    <li>
                      个人措施：{{ scope.row.detailss.personage }}</li>
                    <li>
                      工程措施： {{ scope.row.detailss.projectrola }}</li>
                  </ul>
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="" label="纠正措施" align="center">
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <div @click="goThree(scope.row)">查看详情</div>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>

      </el-tabs>
      <div class="footer">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
          :page-sizes="[10, 20, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>

      <!-- <div class="out"></div> -->


    </el-card>
    <el-card v-else-if="current == 3">
      <div class="goBackSecond">
        <el-button type="primary" @click="goBackSecond"> 返回上级 </el-button>
      </div>
      <div class="basicInfo">
        <div class="basicTitle">
          <span>基本信息</span>
        </div>
        <div class="basicBody">
          <div class="basicProject">
            <div>
              项目名称: <span>{{ riskInfo.corporations }}</span>
            </div>
            <div>
              所属公司: <span>{{ riskInfo.frim_name }}</span>
            </div>
            <div>备注:</div>
          </div>
          <div v-if="activeName == 1" class="basicProject">
            <div>
              作业任务: <span>{{ riskInfo.risktype }}</span>
            </div>
            <div>
              作业活动名称: <span> {{ riskInfo.riskname }}</span>
            </div>
            <div>
              作业活动内容: <span>{{ riskInfo.riskpoint }}</span>
            </div>
          </div>
          <div v-else class="basicProject">
            <div>
              设备设施类别: <span>{{ riskInfo.risktype }}</span>
            </div>
            <div>
              设备设施名称: <span> {{ riskInfo.riskname }}</span>
            </div>
            <div>
              设备设施属性: <span>{{ riskInfo.riskpoint }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="riskEvaluation">
        <div class="basicTitle">
          <span>风险评价</span>
        </div>
        <div class="evaluationBody">
          <div>
            风险点名称: <span>{{ riskInfo.riskname }}</span>
          </div>
          <div>
            工作内容: <span> {{ riskInfo.riskpoint }}</span>
          </div>
          <div>
            主要危害因素: <span>{{ riskInfo.hazard }}</span>
          </div>
          <div>
            工程措施: <span>{{ riskInfo.projectrola }}</span>
          </div>
          <div>
            管理措施: <span>{{ riskInfo.controls }}</span>
          </div>
          <div>
            管理措施: <span>{{ riskInfo.manager }}</span>
          </div>
        </div>
      </div>
      <div v-if="activeName == 1" class="severity">
        <div class="severityTitle basicTitle">
          <div>L (事故发生可能性)</div>
          <div>E (人员暴露频繁程度)</div>
          <div>C (发生事故后果可能性)</div>
          <div>风险等级</div>
        </div>
        <div class="severityBody">
          <div>{{ riskInfo.L }}</div>
          <div>{{ riskInfo.E }}</div>
          <div>{{ riskInfo.C }}</div>
          <div>{{ riskInfo.riskrank }}</div>
        </div>
      </div>
      <div v-else class="severity">
        <div class="severityTitle basicTitle">
          <div>L (事故发生可能性)</div>

          <div>S (发生事故后果可能性)</div>
          <div>风险等级</div>
        </div>
        <div class="severityBody">
          <div>{{ riskInfo.L }}</div>

          <div>{{ riskInfo.C }}</div>
          <div>{{ riskInfo.riskrank }}</div>
        </div>
      </div>
      <div class="controls">
        <div class="basicTitle">
          <span>分级管控</span>
        </div>
        <div class="controlsBody">
          <div>
            负责人:
            <span v-if="riskInfo.control4">公司级:{{ riskInfo.control4 }}</span>
            <span v-if="riskInfo.control1">项目级:{{ riskInfo.control1 }}</span>
            <span v-if="riskInfo.control2">班组级:{{ riskInfo.control2 }}</span>
            <span v-if="riskInfo.control3">施工人员:{{ riskInfo.control3 }}</span>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  getProjectRiskInfo,
  getSecondRiskInfo,
  getThreeRiskInfo,
  getRiskNum,
  getProjectRiskNum,

  getMajorProjectRisk,
  getMajorRisk
} from "../../../request/risk";

export default {
  data() {
    return {
      // 第二个卡片 搜索表单
      searchForm: {
        sb_hd: "", //判断获取作业活动风险或设备设施风险

        name: "", //作业活动风险
        project_id: "", //项目id
        page: 1,
        size: 5,
      },
      project_Name: "",//第一个卡片 搜索项目名称
      //   第一个卡片表格数据
      tableData1: [],
      // 第二个卡片表格数据

      total: 10, // 表格分页总数
      page: 1,
      pageSize: 10,

      current: 1, // 第几个卡片
      flag: true, //判断用户权限 系统管理员  政府人员 true    项目管理员  普通用户  false
      activeName: "1", //标签页
      workTableData: [], // 作业活动风险表格
      equimentTableData: [], // 设备设施风险
      riskOptions: [
        {
          label: "重大风险",
          value: "重大风险",
        },
        {
          label: "较大风险",
          value: "较大风险",
        },
        {
          label: "一般风险",
          value: "一般风险",
        },
        {
          label: "低风险",
          value: "低风险",
        },
      ],
      projectId: "", //点击项目详情 记录id
      projectName: "", //项目名称
      companyName: "", //公司名称
      riskInfo: {}, // 第三个卡片数据
      riskNum: {}, //所有项目风险数量
      projectRiskNum: {}, // 选中项目风险数量
      // control1:"",//项目级负责人
      // control2:"",//班组级负责人
      // control3:"",//施工人员
      pjtid:'',
    };
  },
  watch: {
    $route() {
      this.pjtid = this.$route.query.id
    },
    pjtid() {
      this.current = 1;
      this.getProjectRisk();
    },
  },
  created() {
    this.getProjectRisk();
  },
  methods: {
    // 根据权限 获取风险统计
    getProjectRisk() {
      console.log(localStorage.getItem("rank"));
      this.flag =
        localStorage.getItem("rank") == 0 || localStorage.getItem("rank") == 1
          ? true
          : false;

      if (this.flag) {
        // 系统管理员  政府账号  显示第一个卡片  获取所有项目
        this.current = 1;

        this.getProjectRiskStatistical();
        // this.getRiskNumber();
      } else {
        // 项目管理员  普通用户  显示第二个卡片  获取所属项目所有风险
        this.current = 2;
        this.projectId = localStorage.getItem("corporations");
        this.getSecondRisk();
        this.getProjectRiskNumber();
      }
    },
    // 获取所有项目风险数量
    // async getRiskNumber() {
    //   const res = await getRiskNum();
    //   this.riskNum = res.data.data;
    // },
    // 第一个卡片搜索
    searchProject() {
      this.getProjectRiskStatistical()
    },
    // 第一个卡片重置
    resetProject_Name() {
      this.project_Name = "";
      this.getProjectRiskStatistical()

    },
    // 获取所有项目风险统计
    async getProjectRiskStatistical(page) {

      let msg = {
        unique_identification: localStorage.getItem("uid"),
        name: this.project_Name,
        page: page || this.page,
        size: this.pageSize,
      };
      if (localStorage.getItem('isdealer') == 'true') {
        if (localStorage.getItem('type') == 'pro') {
          msg.pro_id = localStorage.getItem('project')
        } else if (localStorage.getItem('type') == 'firm') {
          msg.firm_id = localStorage.getItem('project')
        }
      }
      const res = await getMajorProjectRisk(msg);
      console.log(res);
      this.total = res.data.count;
      this.tableData1 = res.data.data;
      this.riskNum.risk_hd = res.data.activity;
      this.riskNum.risk_sb = res.data.equipment;
      this.riskNum.risk_jd = res.data.num;
      this.riskNum.gongdi = res.data.gongdi;
    },
    //   表格表头颜色
    headerCellStyle(row, column, rowIndex, columnIndex) {
      //根据报警级别显示颜色
      // return 'background:SlateGray ;color:white'
      return "background:#4c7cfc ;color:white";
    },
    // 表格颜色
    cellStyle(row, column, rowIndex, columnIndex) {
      //根据报警级别显示颜色
      // console.log(row);
      // console.log(row.column);
      if (row.column.label === "重大风险统计") {
        return "color:red";
      } else if (row.column.label === "操作") {
        return "color:#3090f0;cursor:pointer;";
      } else if (
        row.column.label === "风险等级" &&
        row.row.riskrank === "低风险"
      ) {
        return "color:blue";
      } else if (
        row.column.label === "风险等级" &&
        row.row.riskrank === "一般风险"
      ) {
        return "color:skyblue";
      } else if (
        row.column.label === "风险等级" &&
        row.row.riskrank === "较大风险"
      ) {
        return "color:orange";
      } else if (
        row.column.label === "风险等级" &&
        row.row.riskrank === "重大风险"
      ) {
        return "color:red";
      }
    },

    // 切换每页显示几条数据
    handleSizeChange() {
      // console.log(this.pageSize);
      if (this.current == 1) {
        // 第一个卡片分页获取
        this.getProjectRiskStatistical();
      } else if (this.current == 2) {
        // 第二个卡片分页获取
        this.getSecondRisk();
      } else if (this.current == 3) {
        // 第三个卡片获取
      }
    },
    // 切换页码
    handleCurrentChange(val) {
      if (this.current == 1) {
        // 第一个卡片分页获取
        this.getProjectRiskStatistical(val);
      } else if (this.current == 2) {
        // 第二个卡片分页获取
        this.getSecondRisk(val);
      } else if (this.current == 3) {
        // 第三个卡片获取
      }
    },
    // 点击项目详情
    getPorjectRisk(row) {

      console.log(row);

      this.current = 2;
      this.page = 1;
      this.pageSize = 10;

      this.projectName = row.row.name;
      this.companyName = row.row.corporations;
      this.projectId = row.row.id;
      // 获取项目下的风险
      this.getSecondRisk();
      // 获取项目下 风险数量统计
      // this.getProjectRiskNumber();
    },
    // 获取选定的项目下风险数量统计
    async getProjectRiskNumber() {
      let msg = {
        project_id: this.projectId,
      };
      const res = await getProjectRiskNum(msg);
      this.projectRiskNum = res.data.data;
    },
    // 获取作业活动风险 和设备设施风险
    async getSecondRisk(page) {
      // console.log(id);
      // console.log(this.activeName);
      this.searchForm.project_id = this.projectId;
      this.searchForm.sb_hd = this.activeName; // 区分作业活动风险 设备设施风险
      this.searchForm.page = page || this.page;
      this.searchForm.size = this.pageSize;
      const res = await getMajorRisk(this.searchForm);
      console.log(res);
      this.total = res.data.count;
      if (this.activeName == 1) {
        this.workTableData = res.data.data;
        for (let i = 0; i < this.workTableData.length; i++) {
          this.workTableData[i].control1 =
            this.workTableData[i].functionarys == null
              ? ""
              : this.workTableData[i].functionarys.control1;
          this.workTableData[i].control2 =
            this.workTableData[i].functionarys == null
              ? ""
              : this.workTableData[i].functionarys.control2;
          this.workTableData[i].control3 =
            this.workTableData[i].functionarys == null
              ? ""
              : this.workTableData[i].functionarys.control3;
        }
      } else {
        this.equimentTableData = res.data.data;
        for (let i = 0; i < this.equimentTableData.length; i++) {
          this.equimentTableData[i].control1 =
            this.equimentTableData[i].functionarys == null
              ? ""
              : this.equimentTableData[i].functionarys.control1;
          this.equimentTableData[i].control2 =
            this.equimentTableData[i].functionarys == null
              ? ""
              : this.equimentTableData[i].functionarys.control2;
          this.equimentTableData[i].control3 =
            this.equimentTableData[i].functionarys == null
              ? ""
              : this.equimentTableData[i].functionarys.control3;
        }
      }

    },

    // 第二个卡片 标签页切换
    handleClick(row) {
      this.getSecondRisk();
    },

    // 搜索某个项目下的风险
    searhProjectBtn() {
      this.page = 1;
      this.pageSize = 10;
      // console.log('ddd');
      this.getSecondRisk();
    },
    // 获取选定的风险详情
    goThree(row) {
      // console.log(row.id);
      this.current = 3;
      this.getRiskInfo(row.id);
    },
    // 从第二个卡片返回第一个卡片
    goProject() {
      this.current = 1;
      this.page = 1;
      this.pageSize = 10;
      this.getProjectRiskStatistical();
    },
    // 从第三个卡片返回第二个卡片
    goBackSecond(row) {
      this.current = 2;
      this.page = 1;
      this.pageSize = 10;

      // 获取选中的风险详细信息
    },
    async getRiskInfo(id) {
      let msg = {
        id: id,
      };
      const res = await getThreeRiskInfo(msg);
      // console.log(res);
      this.riskInfo = res.data.data[0];
      // console.log(this.riskInfo);
      this.riskInfo.hazard = this.riskInfo.detail.hazard;
      this.riskInfo.projectrola = this.riskInfo.detail.projectrola;
      this.riskInfo.controls = this.riskInfo.detail.controls;
      this.riskInfo.manager = this.riskInfo.detail.manager;

      this.riskInfo.E = this.riskInfo.riskran.E;

      this.riskInfo.control1 = this.riskInfo.functionarys.control1;
      this.riskInfo.control2 = this.riskInfo.functionarys.control2;
      this.riskInfo.control3 = this.riskInfo.functionarys.control3;
      this.riskInfo.control4 = this.riskInfo.functionarys.control4;

      if (this.activeName == 1) {
        this.riskInfo.L = this.riskInfo.riskran.L;
        this.riskInfo.C = this.riskInfo.riskran.C;
      } else if (this.activeName == 2) {
        this.riskInfo.L = this.riskInfo.riskran.K;
        this.riskInfo.C = this.riskInfo.riskran.Y;
      }
    },
    // 第二个卡片重置搜索条件
    resetHandle() {
      this.searchForm.risk_type = "";
      this.searchForm.risk_name = "";
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  height: 100%;

  .statistical {
    width: 100%;
    height: 150px;
    display: flex;
    padding-top: 20px;
    justify-content: space-between;

    //   background-color: pink;
    .left {
      display: flex;

      .riskBg {
        width: 160px;
        height: 105px;
        background-color: skyblue;
        background: url("../../../assets/img/risk1.png") no-repeat;
        padding: 20px 0 0 20px;
        margin-right: 20px;
        border-radius: 5px;

        .riskTitle {
          font-size: 14px;
          color: #fff;
          margin-bottom: 10px;
        }

        .riskNum {
          font-size: 22px;
          color: #fff;
        }
      }

      > :nth-child(2) {
        background: url("../../../assets/img/risk2.png") no-repeat;
      }

      > :nth-child(3) {
        background: url("../../../assets/img/risk2.png") no-repeat;
      }

      > :nth-child(4) {
        background: url("../../../assets/img/risk3.png") no-repeat;
      }

      > :nth-child(5) {
        background: url("../../../assets/img/risk4.png") no-repeat;
      }
    }

    .right {
      padding-top: 30px;
    }
  }

  .projectInfo {
    height: 34px;
    display: flex;
    justify-content: space-between;
  }

  .search {
    width: 100%;
    height: 80px;

    // background-color: brown;
    .searhBtn {
      margin-left: 20px;
    }
  }

  .main {
    width: 100%;
    // height: 400px;
    // background-color: cornflowerblue;
  }

  .out {
    width: 100%;
    height: 850px;
    background-color: pink;
  }

  // 第三个卡片
  .goBackSecond {
    display: flex;
    justify-content: flex-end;
  }

  .basicInfo {
    width: 100%;
    height: 200px;
    padding-top: 20px;
    padding-bottom: 40px;
    // background-color: pink;

    .basicBody {
      width: 100%;
      height: 160px;
      color: #888;
      padding-left: 15px;

      // background-color: pink;
      .basicProject {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        div {
          flex: 1;

          span {
            color: #333;
            padding-left: 10px;
          }
        }

        // background-color: skyblue;
      }
    }
  }

  .riskEvaluation {
    width: 100%;
    height: 300px;

    // background-color: pink;
    .evaluationBody {
      padding-left: 15px;

      div {
        width: 100%;
        height: 40px;
        color: #888;
        line-height: 40px;

        span {
          color: #333;
          padding-left: 10px;
        }
      }
    }
  }

  .severity {
    width: 100%;
    height: 100px;

    // background-color: pink;
    .severityTitle {
      background-color: #acb4cc;
      display: flex;
      align-items: center;

      div {
        flex: 1;
      }
    }

    .severityBody {
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;

      div {
        flex: 1;
        height: 100%;
        line-height: 60px;
        padding-left: 10px;
      }
    }
  }

  .controls {
    width: 100%;
    height: 100px;

    .controlsBody {
      width: 100%;
      height: 60px;
      line-height: 60px;
      color: #888;

      span {
        margin: 0 10px;
        color: #333;
      }
    }
  }

  // 公共样式类 标题
  .basicTitle {
    width: 100%;
    height: 40px;
    background-color: #4c7cfc;
    letter-spacing: 2px;
    border-radius: 4px 4px 0 0;
    line-height: 40px;
    padding-left: 15px;

    span {
      color: #fff;
      font-size: 18px;
      // letter-spacing: 2px;
    }
  }
}
</style>